import {h} from 'preact';

const Text = ({children}) => children;
import LinksToSocialNetworks from "../LinksToSocialNetworks/LinksToSocialNetworks";
import styles from "./styles.scss";

const Footer = () => {
    let getFullYear = () => {
        return new Date().getFullYear();
    }
    return (
        <footer>
            <div className={styles.footerContent}>
                <div className={styles.footerLeft}>
                    <div className={styles.logo}>
                        <img src="/assets/logo.svg" alt="logo" title="logo"/>
                    </div>
                    <p className={styles.footerInfo}><Text id='footer.year'>© ИТРУМ 2016-{getFullYear()}</Text></p>
                    <p className={styles.footerInfo}><Text id='footer.protected'>Все права защищены</Text></p>
                    <p className={styles.footerInfo}><a onClick={() => location.href = "/docs"}>
                        <Text id='footer.docs'>Результаты специальной оценки условий труда</Text>
                    </a></p>
                    <p className={styles.footerInfo}>
                        <a onClick={() => location.href = "/privacy"}><Text id='footer.privacy_policy'>Политика
                            конфиденциальности</Text></a>
                    </p>


                </div>
                <div className={styles.footerRight}>
                    <div className={styles.footerPhone}><a href="tel:+79914250850 ">+ 7 (991) 425 08 50</a></div>
                    <div className={styles.footerEmail}><a href="mailto:hello@itrum.ru">hello@itrum.ru</a></div>
                    <LinksToSocialNetworks/>
                </div>
            </div>

            <div className={styles.backgroundCircle}/>
        </footer>
    );
};

export default Footer;
